<template>
  <div class='card card-top card-top-primary'>
    <div class='card-header'>
      <vb-headers-card-header :data="{ title: 'Üyelik Paket Yönetimi' }" />
    </div>
    <div class='card-body'>
      <div class='row'>
        <template v-for='(item, index) in subscriptionPlans' :key='item.id.toString() + "_subscription" '>
          <div class='col-xl-3 col-lg-4 col-md-5 col-sm-6 col-12'>
            <div class='card card-top card-top-success'>
              <div class='card-header'>
                <vb-headers-card-header :data='{ title: `${item.durationAsMonths} Aylık` }' />
              </div>
              <div class='card-body'>
                <a-form
                  :loading='loadingForm'
                  label-align='left'
                  layout='vertical'
                >
                  <a-form-item label='Iyzico Referans Adı'>
                    <a-input v-model:value='item.iyzicoReferenceCode' placeholder='appname_price_duration' :maxlength='100'>
                      <template #suffix>
                        <a-tooltip title="Iyzico'da bulunan aylık abonelik ürününün 'Name' alanı girilmelidir">
                          <info-circle-outlined />
                        </a-tooltip>
                      </template>
                    </a-input>

                    <a-button v-if='subscriptionPlansFromServer[index].iyzicoReferenceCode !== item.iyzicoReferenceCode' :loading='loadingIyzicoReference === item.id' @click='updateIyzicoReference(item)' class='btn btn-success mt-2'>
                      <template #icon>
                        <sync-outlined />
                      </template>
                      Güncelle
                    </a-button>
                  </a-form-item>

                  <a-form-item label='Iyzico Paket Fiyatı'>
                    <a-input v-model:value='item.price' addon-after='₺' />
                    <a-button v-if='subscriptionPlansFromServer[index].price.toString() !== item.price.toString()' :loading='loadingPrice === item.id'
                              :disabled='item.price === "" || parseInt(item.price) < 1 || parseInt(item.price) > 999999' @click='updatePrice(item)'
                              class='btn btn-success mt-2'>
                      <template #icon>
                        <sync-outlined />
                      </template>
                      Güncelle
                    </a-button>
                  </a-form-item>

                  <a-form-item label='App Store Product ID'>
                    <a-input v-model:value='item.iosProductId' placeholder='appname_price_duration' :maxlength='100'>
                      <template #suffix>
                        <a-tooltip title="Revenuecat'de bulunan aylık abonelik ürününün 'Identifier' alanı girilmelidir">
                          <info-circle-outlined />
                        </a-tooltip>
                      </template>
                    </a-input>

                    <a-button v-if='subscriptionPlansFromServer[index].iosProductId !== item.iosProductId' :loading='loadingProductId === item.id' @click='updateProductID(item)' class='btn btn-success mt-2'>
                      <template #icon>
                        <sync-outlined />
                      </template>
                      Güncelle
                    </a-button>
                  </a-form-item>

                  <a-form-item label='App Store Paket Fiyatı'>
                    <a-input v-model:value='item.iosPrice' addon-after='₺' />
                    <a-button v-if='item.iosPrice !== null && subscriptionPlansFromServer[index].iosPrice.toString() !== item.iosPrice.toString()' :loading='loadingIosPrice === item.id'
                              :disabled='item.iosPrice === "" || parseInt(item.iosPrice) < 1 || parseInt(item.iosPrice) > 999999' @click='updateIosPrice(item)'
                              class='btn btn-success mt-2'>
                      <template #icon>
                        <sync-outlined />
                      </template>
                      Güncelle
                    </a-button>
                  </a-form-item>
                </a-form>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { notification } from 'ant-design-vue'
import { InfoCircleOutlined } from '@ant-design/icons-vue'
import { SubscriptionPlanAdministration } from '../../services/api-service'

export default {
  name: 'VbYelikPaketleri',
  components: {
    InfoCircleOutlined,
  },
  data() {
    return {
      subscriptionPlans: [],
      subscriptionPlansFromServer: [],
      loadingPrice: null,
      loadingIosPrice: null,
      loadingProductId: null,
      loadingIyzicoReference: null,
      loadingForm: true,
    }
  },
  mounted() {
    this.loadSubscriptionPlans()
  },
  methods: {
    async updatePrice(item) {
      this.loadingPrice = item.id

      try {
        await SubscriptionPlanAdministration.updatePriceOfSubscriptionPlan({ id: item.id, newPrice: item.price })
        this.subscriptionPlansFromServer.find(x => x.id === item.id).price = item.price

        notification.success({
          message: 'İşlem Başarılı!',
          description: 'Paket Fiyatı Başarıyla Güncellendi.',
        })
      } catch {
      }

      this.loadingPrice = null
    },
    async updateIosPrice(item) {
      this.loadingIosPrice = item.id

      try {
        await SubscriptionPlanAdministration.updateIosPriceOfSubscriptionPlan({ id: item.id, iosPrice: item.iosPrice })
        this.subscriptionPlansFromServer.find(x => x.id === item.id).iosPrice = item.iosPrice

        notification.success({
          message: 'İşlem Başarılı!',
          description: 'Paket Fiyatı Başarıyla Güncellendi.',
        })
      } catch {
      }

      this.loadingIosPrice = null
    },
    async updateProductID(item) {
      this.loadingProductId = item.id

      try {
        await SubscriptionPlanAdministration.updateAppStoreProductIdOfSubscriptionPlan({ id: item.id, appStoreProductId: item.iosProductId })
        this.subscriptionPlansFromServer.find(x => x.id === item.id).iosProductId = item.iosProductId

        notification.success({
          message: 'İşlem Başarılı!',
          description: 'App Store Product ID Başarıyla Güncellendi.',
        })
      } catch {
      }

      this.loadingProductId = null
    },
    async updateIyzicoReference(item) {
      this.loadingIyzicoReference = item.id

      try {
        await SubscriptionPlanAdministration.updateIyzicoPlanReferenceCodeOfSubscriptionPlan({ id: item.id, newPlanReferenceCode: item.iyzicoReferenceCode })
        this.subscriptionPlansFromServer.find(x => x.id === item.id).iyzicoReferenceCode = item.iyzicoReferenceCode

        notification.success({
          message: 'İşlem Başarılı!',
          description: 'Iyzico Referans Adı Başarıyla Güncellendi.',
        })
      } catch {
      }

      this.loadingIyzicoReference = null
    },
    async loadSubscriptionPlans() {
      this.loadingForm = true

      try {
        const { data } = await SubscriptionPlanAdministration.listSubscriptionPlansForAdministration()
        this.subscriptionPlans = data.subscriptionPlans
        this.subscriptionPlansFromServer = JSON.parse(JSON.stringify(data.subscriptionPlans))
      } catch (e) {
      }

      this.loadingForm = false
    },
  },
}
</script>
